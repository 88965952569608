export default {
  updated(element) {
    try {
      var string = element.value
      string = string.replaceAll('+7', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '')
      string = string.replaceAll('+', '')

      if (string.length == 0) string = '+7'
      else if (string.length < 3) string = '+7(' + string.substring(0, 3)
      else if (string.length < 6) string = '+7(' + string.substring(0, 3) + ')' + string.substring(3, 6)
      else if (string.length < 8) {
        string = '+7(' + string.substring(0, 3) + ')' + string.substring(3, 6) + '-' + string.substring(6, 8)
      } else {
        string = '+7(' + string.substring(0, 3) + ')' + string.substring(3, 6) + '-' + string.substring(6, 8) + '-' + string.substring(8, 10)
      }
      if (string[string.length - 1] == '-' || string[string.length - 1] == ')' || string[string.length - 1] == '(') string = string.substring(0, (string.length - 1))

      element.value = string
    } catch (e) {
      console.error(e)
    }
  },
  name: 'phone'
}