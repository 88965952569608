import { required, email, minLength, maxLength, sameAs, helpers, requiredIf } from '@vuelidate/validators'
import { phone } from 'phone'

const requiredRu = helpers.withMessage('Обязательное поле', required)
const emailRu = helpers.withMessage('Некорректный email', email)
const numbers = helpers.withMessage('Введено не число', helpers.regex(/^(\d+)$/))
const numbersWithEmpty = helpers.withMessage('Введено не число', helpers.regex(/^(\d*)$/))
const phoneNumber = helpers.withMessage('Некорректный номер телефона', (value) => value === '' ? true : phone(value, {country: 'RU'}).isValid)
const onlyLatin = helpers.withMessage('Введены не латинские символы', helpers.regex(/^[A-Za-z]+$/))

function minLengthRu(value = 6) {
  return helpers.withMessage(
    ({
      $pending,
      $invalid,
      $params,
      $model
    }) => `Поле должно содержать хотя бы ${value} ${declOfNum(value, ['символ', 'символа', 'символов'])}`,
    minLength(value)
  )
}
function maxLengthRu(value = 12) {
  return helpers.withMessage(
    ({
      $pending,
      $invalid,
      $params,
      $model
    }) => `Поле не должно содержать больше ${value} ${declOfNum(value, ['символ', 'символа', 'символов'])}`,
    maxLength(value)
  )
}
function sameAsRu(field, value = '') {
  return helpers.withMessage(
    ({
      $pending,
      $invalid,
      $params,
      $model
    }) => `Не соотвествует полю ${value}`,
    sameAs(field)
  )
}

function requiredIfRu(conditionalFunction) {
  return helpers.withMessage(
    ({
      $pending,
      $invalid,
      $params,
      $model
    }) => 'Обязательное поле',
    requiredIf(conditionalFunction)
  )
}

export {
  requiredRu,
  requiredIfRu,
  emailRu,
  numbers,
  numbersWithEmpty,
  phoneNumber,
  onlyLatin,
  minLengthRu,
  maxLengthRu,
  sameAsRu
}