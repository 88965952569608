import {defineStore} from 'pinia'
import axios from 'axios'

export const useUserStore = defineStore({
  id: 'user',

  state: () => ({
    userNotificationTitle: '',
    userNotificationDesc: '',

    userName: '',
    userEmail: '',
    userPhone: '',
    userCompany: '',
    userProject: '',

    userCallName: '',
    userCallPhone: '',
    userCallTime: '',

    errorMessage: 'Произошла непредвиденная ошибка. Попробуйте снова.',

    toEmails: [
      {"email": "sales@botorama.ru"},
      {"email": "a.larionova@carbonfay.ru"},
      {"email": "a.larionova@botorama.ru"},
      {"email": "a.slusareva@carbonfay.ru"},
      {"email": "phil@carbonfay.ru"},
      {"email": "j.chudesa@carbonfay.ru"},
      {"email": "t.poruchikova@carbonfay.ru"},
    ]
  }),
  getters: {
    actionMessage: state => {
      let message = `<div>Имя: ${state.userName}</div><div>Email: ${state.userEmail}</div><div>Телефон: ${state.userPhone}</div><div>Компания: ${state.userCompany}</div><div>Описание проекта: ${state.userProject}</div>`

      return message
    },
    callMeMessage: state => {
      let message = '<div>Имя: ' + state.userCallName + '</div><div>Телефон: ' + state.userCallPhone + '</div><div>Время: ' + (state.userCallTime == '' ? 'Сейчас/Не отмечено': state.userCallTime) + '</div>'

      return message
    }
  },
  actions: {
    setDefaultUser() {
      this.userName = '',
      this.userEmail = '',
      this.userPhone = '+7',
      this.userCompany = '',
      this.userProject = '',
      this.userCallName = '',
      this.userCallPhone = '+7',
      this.userCallTime = ''
    },
    async sendingFormAction(callback = {}) {
      axios
        .post("/api/email/delivery", {
          "email": {
            "subject": "Заявка с лендинга",
            "html": this.actionMessage,
            "custom_unsubscribe_link": "true",
            "to": this.toEmails,
            "from": {
              "email": "support@botorama.ru",
              "name": "Боторама"
            }
          }
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(response)
            this.setDefaultUser()
            this.userNotificationTitle = 'Благодарим за вашу заявку!'
            this.userNotificationDesc = 'Мы ее уже обрабатываем и в ближайшее время с вами свяжемся.'

            if (typeof callback == 'function') {
              callback('success', [])
            }
          }
          else {
            console.log(response)
            this.userNotificationTitle = 'Внимание!'
            this.userNotificationDesc = this.errorMessage

            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          this.userNotificationTitle = 'Внимание!'
          this.userNotificationDesc = rhis.errorMessage

          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
        })
    },
    async sendingFormCallMe(callback = {}) {
      axios
        .post("/api/email/delivery", {
          "email": {
            "subject": "Заявка с лендинга",
            "html": this.callMeMessage,
            "custom_unsubscribe_link": "true",
            "to": this.toEmails,
            "from": {
              "email": "support@botorama.ru",
              "name": "Боторама"
            }
          }
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(response)
            this.setDefaultUser()
            this.userNotificationTitle = 'Благодарим за вашу заявку!'
            this.userNotificationDesc = 'Мы ее уже обрабатываем и в ближайшее время с вами свяжемся.'

            if (typeof callback == 'function') {
              callback('success', [])
            }
          }
          else {
            console.log(response)
            this.userNotificationTitle = 'Внимание!'
            this.userNotificationDesc = this.errorMessage

            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          this.userNotificationTitle = 'Внимание!'
          this.userNotificationDesc = this.errorMessage

          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
        })
    }
  }
})