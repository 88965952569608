import { createApp } from 'vue'
import { createPinia } from 'pinia'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import directives from '@/directives';
import '@/assets/styles/tailwind.css';

const pinia = createPinia()

axios.defaults.baseURL = "https://platform.bafsy.com"
axios.defaults.headers.common['Accept'] = 'application/json'

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error) {
      console.log(error)

      return Promise.reject(error)
    }
  }
)

const app = createApp(App)

directives.forEach(directive => {
  app.directive(directive.name, directive)
})

app
  .use(pinia)
  .use(router)
  .mount('#app')