export default {
  mounted: function(element, binding) {
    element.classList.add('lg:opacity-0')
    let f = function(event) {
      if (binding.value(event, element, binding.arg)) {
        window.removeEventListener('scroll', f)
      }
    }

    window.addEventListener('scroll', f)
  },
  name: 'scroll'
}